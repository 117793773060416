import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Creators from "../components/creators-section"

const CreatorsPage = () => {
  return (
    <Layout>
      <Seo title="Criadores" />
      <Creators />
    </Layout>
  )
}

export default CreatorsPage
